
import * as Components from "./Lancers"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "lancers"
}

