import React from 'react'
import './sections.scss'

export const sections = [
  {
    h1: "Visit Us",
    sub: "Brick Oven Pizza & Others",
    img: "https://afag.imgix.net/lancers-family-restaurant/lets-go-to-lancers.jpg?w=800",
    content: "697 N Victory Blvd, Burbank, CA 91502",
    // link: "/menu",
    className: " visit-us"
  },
  {
    h1: "Lancers Cocktails",
    // link: "/the-brick-",
    // sub: "Live Music thursday - saturday",
    // link: "/cocktails",
    // linkText: "Cocktail Menu",
    img: "https://images.unsplash.com/photo-1509669803555-fd5edd8d5a41?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80",
    // content: "Lancers offers a selection of cocktails, beer and a curated wine list."
    link: "/menu/W2Ot7RaVgjOQmtMijYNd",
    // linkText: "View Schedule"
    printableMenuLink: "/public/lancers/lancers-cocktail-menu.pdf"
  },
  {
    h1: "Happy Hour",
    // link: "/the-brick-",
    // sub: "Live Music thursday - saturday",
    // link: "/cocktails",
    // linkText: "Cocktail Menu",
    img: "https://images.unsplash.com/photo-1519671482749-fd09be7ccebf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80",
    // content: "Lancers offers a selection of cocktails, beer and a curated wine list."
    link: "/menu/S4nGwE88HL0R2a72UBzw",
    // linkText: "View Schedule"
    printableMenuLink: "/public/lancers/lancers-happy-hour-menu.pdf"
  },


  {
    h1: "Salads",
    sub: "gather with friends",
    img: "https://afag.imgix.net/lancers-family-restaurant/salads.jpg?w=800",
    link: "/menu/QxhRJkSE7S07Ol5b8wyl", 
    content: ""
  },
  {
    h1: "Breakfast",
    sub: "gather with friends",
    img: "https://afag.imgix.net/lancers-family-restaurant/breakfast.jpg?w=800",
    content: "",
    link: "/menu/QxhRJkSE7S07Ol5b8wyl"
  },
  {
    h1: "Coffee",
    sub: "gather with friends",
    img: "https://afag.imgix.net/lancers-family-restaurant/coffee.jpeg?w=800",
    content: ""
  },

  {
    h1: "Lunch",
    img: "https://afag.imgix.net/lancers-family-restaurant/lunch.jpg?w=800",
    link: '/menu/b2X8mOiB3LIEDIDpmRPy'
    // hero: "https://afag.imgix.net/harvest-moon/in-school-program.jpg?w=1200",
    // link: "/about-us/in-school-program",
    // linkText: "details",
    // pageContentComponent: function () {
    //   return(
    //     <article>
    //       <p>Harvest Moon is an innovator in School Lunch programs. Drawing upon her extensive experience as a corporate executive chef, Christine Drapkin knows how to make school dining a delicious, non-repetitive, and exciting experience. She has re-invented the school lunch program to be so much more than the daily food tray.</p>
    //       <p>Her philosophy is simple: Don’t underestimate your children. Serve them great tasting, nutritious, fresh food and teach them where it comes from. You will see them cultivate their own curiosity for great food and a desire to eat well.</p>
    //       <p>As a mother and expert on feeding children, Christine gained expertise at her own table. “My daughter Sydney went to farmers markets, food stores and restaurants when she was in her stroller. We’d grind her food in a small plastic food mill, and she ate what we did. I knew that by having her taste everything, early on, she’d learn to appreciate fresh, healthy food, and my hunch was right.”</p>
    //       <p>Harvest Moon expertly provides highly nutritious, organic, and tasty school lunches. Christine, or Chef Chris as she is known in school kitchens, has launched and managed daily food programs for schools in LA since 2007. For more information, contact chris@harvestmoonco.com</p>
    //     </article>
    //   )
    // }
  },
  {
    h1: "Chef Ricky",
    sub: "romatic dinner date",
    // link: "/about",
    // linkText: "About our Kitchen",
    img: "https://afag.imgix.net/lancers-family-restaurant/chef-ricky.jpg?w=800",
    content: ""

  },
]

export const sectionPagesDef = sections.map(({ link, linkText, h1: heading, full, hero, gallery=[], pageContentComponent }) => {
  if (!linkText) {return null}
  // if (!)
  return {
    path: link,
    hidden: true,
    component: () => (
      <div className="experience page">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundSize: "fill",
            backgroundPosition: "center"
          }}
          className="header">
          <div className="text">
            <h1>{heading}</h1>
          </div>
        </div>
        {/* <div className="mini-banner">
          <img
            src="http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomspecialsTAB-1-900x97.jpg"
            alt=""/>
        </div> */}
        <div className="content">
          <p>
             <Link to={link}>view {heading} menu</Link>
          </p>

          {pageContentComponent() || <p>{full}</p>}
        </div>

        <div className="gallery">
          {gallery.map((url) => {
            return(
              <div className="item">
                <img src={url} alt=""/>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}).filter((a) => !!a)
