import React, { Component, PureComponent, useEffect, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Catering from './Catering'
import About from './About'
import Contact from './Contact'
// import Menu from './Menu'
import Kitchen from './Kitchen'
import Bakery from './Bakery'
import Wine from './Wine'
import Spirits from './Spirits'
import GiftCards from './GiftCards'
import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
import './wine.scss'
import urls from './urls'
import PoweredBy from './PoweredBy'
import {sections, sectionPagesDef} from './sections'
import ThemeToggle from './ThemeToggle'
import AutoMenu from '../../react/AutoMenu'
import { pick } from '../../DeliveryAddress/helpers';
import InfoHours from '../../react/InfoHours';
import CartBar from './CartBar'
export {CartBar as StickyTopBar}

export const customOrderAppPaths = ["/order"]

const urlMappings = urls.filter(({content, redirect}) => {
  return !!content || !!redirect
}).map(({origin, content, redirect}) => {
  const pageObj = { path: origin, unlisted: true }
  if (content) {
    pageObj.component = content
  }
  if (redirect) {
    console.log(redirect)
    pageObj.component = () => (<Redirect to={redirect}></Redirect>)
  }
  return pageObj
})

export const pages = [
  {path: "/order", label: "Order" },
  {path: "/menu/:id", label: "Menu", component: Menu, hidden: true },
  {path: "/menu", label: "Menu", component: Menu, exact: false },
  // {path: "/gift-cards", label: "Gift Cards", component: GiftCards },
  // {path: "/gift-cards/:status", hidden: true, component: GiftCards },
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/gallery.html", label: "Gallery", component: Gallery},
  // {path: "/breakfast-lunch", label: "breakfast/lunch", component: Kitchen},
  // {path: "/bakery", label: "Bakery", component: Bakery},
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/about-us", label: "About", component: About},
  // {path: "/just-the-facts", label: "Contact", component: Contact},
  // {path: "/delivery", label: "Delivery", component: Delivery},

  ...sectionPagesDef
].concat(urlMappings)

export class SiteNav extends PureComponent {
  render() {
    return null
    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
  {path: "/menu/:id", label: "Menu", component: Menu, hidden: true },
  {path: "/menu", label: "Menu", component: Menu, exact: false },
]

function Menu ({match}) {
  const id = match.params.id

  return(
    <div className="menu-page">
      <div
          className="page-header">

        <div className="page-content">
            <h1>Our Menu</h1>

            <p>For printable menu, see the following list:</p>
            <p>
              <a href="/public/lancers/lancers-food-menu.pdf">Food Print Menu</a>,&nbsp;
          
              <a href="/public/lancers/lancers-beverage-menu.pdf">Beverage Print Menu</a>,&nbsp;
         
              <a href="/public/lancers/lancers-kids-menu.pdf">Kid's Print Menu</a>.
            </p>
            
          
        </div>
        
        </div>
{/* 
        <p
        style={{
          textAlign: 'center',
          fontWeight: 700
        }}
        ><a href="/public/mikaza/mikaza-v2.pdf">PDF Menu</a></p>         */}
      {id ? 
      <AutoMenu
      activate={true}
      categorysToShow={[id]}
      // disableOrder={true}
    ></AutoMenu>  
      :
      <AutoMenu
      activate={true}
        // disableOrder={true}
      ></AutoMenu>    
    }
        
      
    </div>
  )
}



export function Header () {
    // useEffect(() => {
    //   window.location.reload()
    // })
    return (
      <div className="landing-page-wrapper">
        

        <div
          className="hero-banner">
          <div className="text">
            <h2 style={{
              // color: "black",
              // textShadow: "-1px 1px 0 green",
              // marginLeft: "1em",
              // fontSize: "2em"
            }}>
               Lancers <br/>
               Family Restaurant
            </h2>

            <p>
              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
            </p>
            {/* <p className="small">with 5% rewards</p> */}


          </div>
        </div>

        <div className="info">
          <InfoHours></InfoHours>
        </div>

        <div className="features">
          {/* <header>
            <h2>What we Offer</h2>
          </header> */}
          {sections.map(({h1, sub, img, content, link, printableMenuLink, linkText, className=""}) => {
            return(
              <section className={"feature-section" + className}>
                <div className="photo">
                  <img src={img} alt=""/>
                </div>
                <div className="content">
                  <h3>{h1}</h3>
                  <div className="text">
                  {link && <p>
                    <a href={link}>view {h1} menu</a>
                  </p>}
                  {printableMenuLink && <p>
                    <a href={printableMenuLink}>printable menu</a>
                  </p>}
                    <p>{content}</p>
                  </div>
                  <div className="more-content">
                    <Link className="link" to={link}>{linkText}</Link>
                  </div>
                </div>
              </section>
            )
          })}
        </div>




      </div>
    );
}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isBreakfast = (
    (hours >= 0) && (hours < 11)
  )
  const isBrunch = (
    (hours >= 0) && (hours < 15)
  )
  const isLunch = (
    (hours >= 11) && (hours < 15)
  )
  const showLunchMenu = isWeekday && isLunch

  const isDayBreakfast = tag.indexOf('weekday breakfast specials') !== -1 // m-f 6am - 11am
  const isEndBreakfast = tag.indexOf('weekend breakfast specials') !== -1 // sat-sun 6am - 3pm
  const isDayLunch =     tag.indexOf('weekday lunch specials') !== -1     // m-f 11am-3pm
  if (isDayBreakfast || isEndBreakfast || isDayLunch) {
    if (isDayBreakfast && isWeekday && isBreakfast) { return true }
    if (isEndBreakfast && !isWeekday && isBrunch) { return true }
    if (isDayLunch && isWeekday && isLunch) {return true}
    return false
  }
  // if (isDinnerSubmenu && !showLunchMenu) { return true }

  return true
}
